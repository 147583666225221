import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from "../appinfo/table";



const Contacts = () => {

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const contacts=result.contacts;
    const roles = result.roles;
    const appGroupsData = result.app_group;
    const contactsUrl = `${baseUrl}resource/get_contacts/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
    const [appList, setAppList] = useState([]);
    const [appGroups, setAppGroups] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${contactsUrl}?email=${encodeURIComponent(email)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                
            });
            const data = await response.json();
            setData(data);
        };

        fetchData();
    }, []);

    return (
        <Box m="20px">
            
            <DataTable res={data} title="Contacts" roles={roles} />
        </Box>
    );
};

export default Contacts;
