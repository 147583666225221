import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from "../appinfo/table";
const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        marginLeft:5,
        marginTop:8,
        minWidth: 120,
        maxWidth: 140,
    },
}));


const Elastic = () => {
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const elasticUrl = `${baseUrl}resource/elastic/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;

    const [data, setData] = useState([]);
    const state = result.state;
    const [stateList, setStateList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${elasticUrl}?email=${encodeURIComponent(email)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: stateList,

                })
            });
            const data = await response.json();
            setData(data);
        };

        fetchData();
    }, [stateList]);

    return (
        <Box m="20px">
            <FormControl className={classes.formControl} size="small">
                <InputLabel id="app-list-label">State</InputLabel>
                <Select
                    labelId="app-list-label"
                    id="app-list"
                    multiple
                    value={stateList}
                    onChange={(e) => setStateList(e.target.value)}
                    renderValue={(selected) => selected.join(', ')}
                    size="small"
                >
                    {state.map((app) => (
                        <MenuItem key={app} value={app}>
                            <Checkbox checked={stateList.indexOf(app) > -1} />
                            <ListItemText primary={app} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <DataTable res={data} title="Elastic"  />
        </Box>
    );
};

export default Elastic;
