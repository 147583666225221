import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, InputLabel, TextField, Checkbox, ListItemText } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import DataTable from 'pages/newtable';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TagRenderer from 'components/TagRenderer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 400,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AWSVendor = () => {
  const classes = useStyles();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedData = localStorage.getItem('filtersData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);
  const email = result.email;
  const accountLists = result.accounts;
  const awsBillingUrl = `${baseUrl}resource/awsvendorbilling/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;

  const [startMonth, setStartMonth] = useState(dayjs().subtract(1, 'month'));
  const [endMonth, setEndMonth] = useState(dayjs());
  const [selectedAccounts, setSelectedAccount] = useState([]);
  const [data, setData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await fetch(`${awsBillingUrl}?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          start_month: startMonth.format('MM-YYYY'),
          end_month: endMonth.format('MM-YYYY'),
          accounts: selectedAccounts.includes('Select All') ? accountLists : selectedAccounts,
        }),
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startMonth, endMonth, selectedAccounts]);

  const handleSelectAll = (newValue, list, setList, allItems) => {
    if (Array.isArray(newValue)) {
      if (newValue.includes('Select All')) {
        if (list.length === allItems.length) {
          setList([]); 
        } else {
          setList(allItems);
        }
      } else {
        setList(newValue); 
      }
    }
  };

  const isAllSelected = (list, allItems) => list.length === allItems.length;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box m="20px">
        <form>
          <Box sx={{ display: 'flex', flexDirection: 'row'}}>
            <DatePicker
              id="start-month"
              label="Start Month"
              views={['year', 'month']}
              format="MM-YYYY"
              maxDate={dayjs()}
              value={startMonth}
              onChange={(date) => setStartMonth(date)}
              sx={{ width: 120, marginTop: '8px' }}
              slotProps={{ textField: { InputProps: { size: 'small' } } }}
            />

            <DatePicker
              id="end-month"
              label="End Month"
              views={['year', 'month']}
              format="MM-YYYY"
              maxDate={dayjs()}
              value={endMonth}
              onChange={(date) => setEndMonth(date)}
              sx={{ width: 120, marginTop: '8px' }}
              slotProps={{ textField: { InputProps: { size: 'small' } } }}
            />
            <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...accountLists]}
                    disableCloseOnSelect
                    value={selectedAccounts}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedAccounts, accountLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Accounts" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, selectedAccounts, setSelectedAccount, accountLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
          </Box>
        </form>
      </Box>
      <DataTable res={data} title="AWS Vendor Billing Data" appLists={accountLists} labels={selectedAccounts} />
    </LocalizationProvider>
  );
};

export default AWSVendor;
