import { ResponsiveLine } from '@nivo/line';
import { tokens } from "../theme2";
import { useTheme } from "@mui/material";

const MyResponsiveLine = ({ data, isDashboard = false }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <ResponsiveLine
            data={data}
            margin={{ top: 50, right: 140, bottom: 50, left: 60 }}
            xScale={{ type: 'point', min: 0 }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                reverse: false
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Period',
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -20,
                legend: 'Amount',
                legendOffset: -40,
                legendPosition: 'middle',
                tickValues: [],
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={3}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            enablePointLabel={true}
            pointLabel={e=>e.y}
            pointLabelYOffset={-5}
            isInteractive={false}
            enableTouchCrosshair={true}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            theme={{
                dots:{
                    text:{
                        fill: colors.grey[100],
                        fontSize: 14,
                    },
                },
                axis: {
                    domain: {
                        line: {
                            stroke: colors.grey[100],
                        },
                    },
                    legend: {
                        text: {
                            fill: colors.grey[100],
                            fontSize: 14,
                        },
                    },
                    ticks: {
                        line: {
                            stroke: colors.grey[100],
                            strokeWidth: 1,
                        },
                        text: {
                            fill: colors.grey[100],
                            fontSize: 14,
                        },
                    },
                },
                legends: {
                    text: {
                        fill: colors.grey[100],
                        fontSize: 12,
                    },
                },
                tooltip: {
                    container: {
                        color: colors.primary[500],
                    },
                },
            }}
        />
    );
};

export default MyResponsiveLine;
