import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import DataTable from 'pages/newtable';
import { useLocation } from 'react-router-dom';

const Consumer = () => {
    const location = useLocation();
    const { rowData } = location.state;
    const topic = rowData.topic;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const kafkaUrl = `${baseUrl}auditmonitoring/kafkaconsumer/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
    const syncUrl = `${baseUrl}auditmonitoring/kafkasync/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
    const [kafkaData, setKafkaData] = useState([]);

    const fetchData = async () => {
        const response = await fetch(`${kafkaUrl}?email=${encodeURIComponent(email)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                topic: topic
            })
        });
        const data = await response.json();
        setKafkaData(data);
    };

    useEffect(() => {
        fetchData();
    }, [topic]);
    const handleButtonClick = async () => {
        const response = await fetch(`${syncUrl}?email=${encodeURIComponent(email)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "topic_id": rowData.topic_id
            })
        });
        const data = await response.json();
        alert(data.message);
        await fetchData();
    };

    const titleColor = 'lightblue';

    return (
        <Box m="20px">
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Typography variant="h3" sx={{ color: titleColor }}>Topic: </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h3">{rowData.topic}</Typography>
                </Grid>
            </Grid>
            <Grid m="20px 0" container spacing={3}>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Application ID:</Typography>
                  <Typography variant="subtitle1" >{rowData.application_id}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Latest Message DateTime:</Typography>
                  <Typography variant="subtitle1" >{rowData.latest_message_datetime}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Status:</Typography>
                  <Typography variant="subtitle1" >{rowData.status}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Updated:</Typography>
                  <Typography variant="subtitle1" >{rowData.updated}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Partition Count:</Typography>
                  <Typography variant="subtitle1" >{rowData.partition_count}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Consumer Count:</Typography>
                  <Typography variant="subtitle1" >{rowData.consumer_count}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Total Lag:</Typography>
                  <Typography variant="subtitle1">{rowData.total_lag}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Total Partition Offsets:</Typography>
                  <Typography variant="subtitle1" >{rowData.total_partition_offsets}</Typography>
              </Grid>
              <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Description:</Typography>
                  <Typography variant="subtitle1" >{rowData.description}</Typography>
              </Grid>
          </Grid>
          <Button variant="contained" onClick={handleButtonClick}>Sync</Button>
          <DataTable res={kafkaData} title="Consumer Data" />
      </Box>
    );
};

export default Consumer;
