import React, { useEffect, useState } from 'react';
import DataTable from 'pages/datatable';

const NewApi = ({ endpoint ,title}) => {
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    setApiResponse(null);
    setError(null);
    setLoading(true);
    const fetchData = async () => {
      try {
        const encodedData=localStorage.getItem('encodedData');
        const decodedData = atob(encodedData);
        const result = JSON.parse(decodedData);
        const email = result.email;
        const token = process.env.REACT_APP_ICSM_TOKEN;
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const url = `${baseUrl}${endpoint}${token}`;
        const response = await fetch(`${url}?email=${encodeURIComponent(email)}`, {
          method: 'GET'
          // headers: {
          //   'content-type': 'application/json',
          // },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setApiResponse(data);
      } catch (error) {
        console.error('API call failed:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    return () => {

    };
  }, [endpoint]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return <DataTable res={apiResponse} title={title} />;
};

export default NewApi;
