import { Chip } from '@mui/material';
const TagRenderer = ({ value, getTagProps }) => {
    const maxTags = 2;
    const extraCount = value.length - maxTags;
  
    return (
      <>
        {value.slice(0, maxTags).map((option, index) => (
          <Chip
            label={typeof option === 'object' ? option.label : option} 
            {...getTagProps({ index })}
            key={typeof option === 'object' ? option.value : option}
          />
        ))}
        {extraCount > 0 && (
          <Chip
            label={`+${extraCount}`}
            
          />
        )}
      </>
    );
  };
export default TagRenderer