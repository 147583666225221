import React, { useState, useEffect } from 'react';
import { Box,  Typography, Grid,FormControl,useTheme, InputLabel, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'pages/newtable';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 140,
    },
}));

const NodepingResults = () => {
    const location = useLocation();
    const { rowData } = location.state;
    const label = rowData.label;
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const contacts=result.contacts;
    const appLists = result.app_list;
    const appGroupsData = result.app_group;
    const nodepingUrl = `${baseUrl}alertmonitoring/nodepingresults/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
    const [appList, setAppList] = useState([]);
    const [appGroups, setAppGroups] = useState([]);
    const [data, setData] = useState([]);
    const titleColor = 'lightblue';
    const [selectedPeriod, setSelectedPeriod] = useState('3months');
    const fetchData = async (period) => {
        const response = await fetch(`${nodepingUrl}?email=${encodeURIComponent(email)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                label:label,
                period:period
            })
        });
        const data = await response.json();
        setData(data);
    };
    useEffect(() => {
        
        fetchData(selectedPeriod);
    }, []);
    const handleSelectPastPeriod = (period) => {
        
        setSelectedPeriod(period);
        fetchData(period)
      };

    return (
        <Box m="20px">
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Typography variant="h3" sx={{ color: titleColor }}>Label: </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h3">{rowData.label}</Typography>
                </Grid>
            </Grid>
            <Grid m="20px 0" container spacing={3}>
              <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Endpoint:</Typography>
                  <Typography variant="subtitle1" >{rowData.Endpoint}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>App Name:</Typography>
                  <Typography variant="subtitle1" >{rowData.App_Name}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>App Group:</Typography>
                  <Typography variant="subtitle1" >{rowData.app_group}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Type:</Typography>
                  <Typography variant="subtitle1" >{rowData.Type}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Status:</Typography>
                  <Typography variant="subtitle1" >{rowData.Status}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>State:</Typography>
                  <Typography variant="subtitle1" >{rowData.State}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Interval:</Typography>
                  <Typography variant="subtitle1" >{rowData.Interval}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Threshold:</Typography>
                  <Typography variant="subtitle1">{rowData.Threshold}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Sens:</Typography>
                  <Typography variant="subtitle1" >{rowData.Sens}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Status Code:</Typography>
                  <Typography variant="subtitle1" >{rowData.Status_Code}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Content String:</Typography>
                  <Typography variant="subtitle1" >{rowData.Content_String}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Runlocation:</Typography>
                  <Typography variant="subtitle1" >{rowData.Runlocation}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Account Type:</Typography>
                  <Typography variant="subtitle1" >{rowData.Account_Type}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Groups:</Typography>
                  <Typography variant="subtitle1" >{rowData.Groups}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Contacts:</Typography>
                  <Typography variant="subtitle1" >{rowData.Contacts}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Remarks:</Typography>
                  <Typography variant="subtitle1" >{rowData.Remarks}</Typography>
              </Grid>
              <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ color: titleColor }}>Description:</Typography>
                  <Typography variant="subtitle1" >{rowData.description}</Typography>
              </Grid>
          </Grid>
          <FormControl className={classes.formControl} size="small">
            
            <Select
              labelId="period-label"
              id="period"
              value={selectedPeriod}
              onChange={(e) => handleSelectPastPeriod(e.target.value)}
              size="small"
            >
              <MenuItem value="1week">Last 7 Days</MenuItem>
              <MenuItem value="1month">Last 1 Month</MenuItem>
              <MenuItem value="3months">Last 3 Months</MenuItem>
            </Select>
          </FormControl>
            <DataTable res={data} title="Nodeping Results"  />
        </Box>
    );
};

export default NodepingResults;
