import { useEffect, useState } from 'react';
import { Box, Button, IconButton, Typography, useTheme,useMediaQuery,Link } from "@mui/material";
import { tokens } from "../../theme2";
import DataTable from "../newtable"
import Header from "../../components/Header";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const encodedData=localStorage.getItem('encodedData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);
  const email = result.email;
  const menu_list=result.menu_list;
  const role= result.user_role;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState([]);
  const oneglanceUrl=`${baseUrl}resource/oneglance/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;



useEffect(() => {
  const fetchData = async () => {
     
      const response = await fetch(`${oneglanceUrl}?email=${encodeURIComponent(email)}`, {
          method: 'GET',

      });
      const data = await response.json();
      setData(data);
  };

  fetchData();
},[]);

  
  return (
    <Box m="20px">
    {/* HEADER */}
    <Box display="flex" justifyContent="space-between" alignItems="center"
    sx={{
      "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
    }}
    >
      <Box>
      <Header title="DASHBOARD" subtitle="Welcome to the dashboard!" />
      {!menu_list || menu_list.length === 0 ? (
        
        <Header title="No apps are assigned" />
      ) : ""}
      </Box>
      
    </Box>
    <Box>
    {role  && (
      <DataTable res={data} title="One Glance" />
      )}
    </Box>
    

  </Box>
  );
};

export default Dashboard;