import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'pages/newtable';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 140,
    },
}));

const Kafka = () => {
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const statuses = result.statuses;
    const kafkaUrl = `${baseUrl}auditmonitoring/kafkatopic/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;

    const [selectedTopics, setSelectedTopics] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [kafkaData, setKafkaData] = useState([]);
    const [topics, setTopics] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
             const response = await fetch(`${kafkaUrl}?email=${encodeURIComponent(email)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    topics: selectedTopics,
                    statuses: selectedStatuses
                })
            });
            const data = await response.json();
            setKafkaData(data);
            const topicsList = data.map(item => item.topic);
            setTopics(topicsList);
        };

        fetchData();
    }, [selectedTopics, selectedStatuses]);

    return (
        <Box m="20px">
            <FormControl className={classes.formControl} size="small">
                <InputLabel id="topics-label">Topics</InputLabel>
                <Select
                    labelId="topics-label"
                    id="topics"
                    multiple
                    value={selectedTopics}
                    onChange={(e) => setSelectedTopics(e.target.value)}
                    renderValue={(selected) => selected.join(', ')}
                    size="small"
                >
                    {topics.map((topic) => (
                        <MenuItem key={topic} value={topic}>
                            <Checkbox checked={selectedTopics.indexOf(topic) > -1} />
                            <ListItemText primary={topic} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl} size="small">
                <InputLabel id="statuses-label">Status</InputLabel>
                <Select
                    labelId="statuses-label"
                    id="statuses"
                    multiple
                    value={selectedStatuses}
                    onChange={(e) => setSelectedStatuses(e.target.value)}
                    renderValue={(selected) => selected.join(', ')}
                    size="small"
                >
                    {statuses.map((status) => (
                        <MenuItem key={status} value={status}>
                            <Checkbox checked={selectedStatuses.indexOf(status) > -1} />
                            <ListItemText primary={status} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <DataTable res={kafkaData} title="Kafka Data" />
        </Box>
    );
};

export default Kafka;
