import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem,Grid, Typography,Checkbox, ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from "pages/appinfo/table";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 140,
    },
}));

const Schedule = () => {
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const titleColor = 'lightblue';
    const scheduleUrl = `${baseUrl}resource/schedule/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
    const shiftsUrl = `${baseUrl}resource/shiftdetails/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
    const detailsUrl = `${baseUrl}resource/get_details/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
    const [data, setData] = useState([]);
    const [shiftsdata, setShiftsData] = useState([]);
    const [details, setDetails] = useState([]);
    const fetchData = async () => {
        const response = await fetch(`${scheduleUrl}?email=${encodeURIComponent(email)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            
        });
        const data = await response.json();
        setData(data);
    };
    const fetchDetails = async () => {
        const response = await fetch(`${detailsUrl}?email=${encodeURIComponent(email)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            
        });
        const data = await response.json();
        setDetails(data);
    };
    const fetchShiftsData = async () => {
        const response = await fetch(`${shiftsUrl}?email=${encodeURIComponent(email)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            
        });
        const data = await response.json();
        setShiftsData(data);
    };

    useEffect(() => {
        
       
        fetchData();
        fetchShiftsData();
        fetchDetails();
    }, []);

    return (
        <Box m="20px">
            <Grid container spacing={2}>
            {Object.keys(details).map((key) => (
                <Grid item xs={12} key={key}>
                    <Typography variant="subtitle1" sx={{ color: titleColor }}>
                        {key}: {details[key].join(', ')}
                    </Typography>
                </Grid>
            ))}
        </Grid>
            <DataTable res={data} title="Schedule" />
            <DataTable res={shiftsdata} title="Shift Details" />
        </Box>
    );
};

export default Schedule;
