import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { tokens } from "../../theme2";
import { Box, Typography, TextField, FormControl,useTheme, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import MyResponsiveBar from 'charts/NewBar';
import DataTable from 'pages/newtable';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useLocation } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TagRenderer from 'components/TagRenderer';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
    maxWidth: 150,
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 400,
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const CostExplorer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedData=localStorage.getItem('filtersData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);
  const email = result.email;
  const appLists=result.app_list;
  const accountLists=result.accounts;
  const serviceLists=result.services;
  const projectLists=result.projects;
  const location = useLocation();
  const { legendText } = location.state || {};;
  const costExplorerUrl=`${baseUrl}resource/costexplorer/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [groupby, setGroupBy] = useState('Application');
  const [granularity, setGranularity] = useState('daily');
  const [appList, setAppList] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [averageCost, setAverageCost] = useState(0);
  const [count, setCount] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState('past1month');
  useEffect(() => {
    if (legendText) {
      switch (groupby) {
        case 'Application':
          setAppList(legendText.split(','));
          break;
        case 'Service':
          setSelectedServices(legendText.split(','));
          break;
        case 'Account':
          setSelectedAccounts(legendText.split(','));
          break;
        case 'Project':
          setSelectedProjects(legendText.split(','));
          break;
        default:
          break;
      }
    }
  }, [legendText, groupby]);

  useEffect(() => {
    const fetchData = async () => {
      const app_list= appList.length > 0 ? appList : appLists;
      try {
        const response = await fetch(`${costExplorerUrl}?email=${encodeURIComponent(email)}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            app_list: app_list.includes('Select All') ? appLists : app_list,
            groupby,
            startdate: startDate.format('YYYY-MM-DD'),
            enddate: endDate.format('YYYY-MM-DD'),
            granularity,
            account: selectedAccounts.includes('Select All') ? accountLists : selectedAccounts,
            service: selectedServices.includes('Select All') ? serviceLists : selectedServices,
            project: selectedProjects.includes('Select All') ? projectLists : selectedProjects,
          }),
        });
        const data = await response.json();
        
       const transformedData = data.reduce((acc, item) => {
          const { granularity, group_by, c } = item;
          const roundedC = Math.ceil(c * 100) / 100;
          const existingEntry = acc.find(entry => entry.Date === granularity);
          if (existingEntry) {
              existingEntry[group_by] = roundedC;
          } else {
              acc.push({ Date: granularity, [group_by]: roundedC });
          }
          return acc;
      }, []);

        transformedData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
        setTransformedData(transformedData);


        const services = Array.from(new Set(transformedData.flatMap(obj => Object.keys(obj).filter(key => key !== 'Date'))));
        
        const transposedData = {};
        transformedData.forEach(obj => {
            const date = obj.Date;

            services.forEach(service => {
                if (!transposedData[service]) {
                    transposedData[service] = [];
                }
                transposedData[service].push({ Date: date, Value: obj[service] || 0 });
            });
        });
        
        const formattedTransposedDataWithTotal = services.map(service => {
            const serviceData = transposedData[service];
            const totalCost = Math.ceil(serviceData.reduce((acc, obj) => acc + obj.Value, 0) * 100) / 100; // Round up to 2 decimal places
            return {
                [groupby]: service,
                TotalCost: totalCost,
                ...serviceData.reduce((acc, obj) => {
                    acc[obj.Date] = obj.Value;
                    return acc;
                }, {})
            };
        });
        
        setFormattedData(formattedTransposedDataWithTotal);
        
        let period = Object.keys(transformedData).length;
        let count = 0;
        if (transformedData.length > 0) {
            count = Object.keys(transformedData[0]).length - 1;
        }
        setCount(count);
        let totalSum = 0;
        transformedData.forEach(item => {
            Object.keys(item).forEach(key => {
                if (key !== 'Date') {
                    totalSum += item[key];
                }
            });
        });
        let roundedTotalSum = totalSum.toFixed(2);
        setTotalSum(roundedTotalSum);
        let averageCost = totalSum / period;
        let roundedAverageCost = averageCost.toFixed(2);
        setAverageCost(roundedAverageCost);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [startDate, endDate, groupby, granularity, appList, selectedAccounts, selectedServices, selectedProjects]);
  useEffect(() => {
    const currentDate = dayjs();
    const lastMonth = currentDate.subtract(1, 'month');
    setStartDate(lastMonth);
    setEndDate(currentDate);
  }, []);


  const handleSelectAll = (newValue, list, setList, allItems) => {
    if (Array.isArray(newValue)) {
      if (newValue.includes('Select All')) {
        if (list.length === allItems.length) {
          setList([]); 
        } else {
          setList(allItems);
        }
      } else {
        setList(newValue); 
      }
    }
  };

  const isAllSelected = (list, allItems) => list.length === allItems.length;


  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleSelectPastPeriod = (period) => {
    const currentDate = dayjs();
    switch (period) {
      case 'past7days':
        setStartDate(currentDate.subtract(6, 'day'));
        setEndDate(currentDate);
        break;
      case 'past1month':
        setStartDate(currentDate.subtract(1, 'month'));
        setEndDate(currentDate);
        break;
      case 'past3months':
        setStartDate(currentDate.subtract(3, 'month'));
        setEndDate(currentDate);
        break;
      default:
        break;
    }
    setSelectedPeriod(period);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box m="20px">
      <form>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>

        <DatePicker
          id="start-date"
          label="Start Date"
          format="DD/MM/YYYY"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          sx={{ width: 140, marginTop:"8px",  }}
          slotProps={{ textField: { InputProps: { size: 'small' } } }}
          maxDate={endDate}
        />

        <DatePicker
          id="end-date"
          label="End Date"
          format="DD/MM/YYYY"
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          sx={{ width: 140, marginTop:"8px" }}
          slotProps={{ textField: { InputProps: { size: 'small' } } }}
          maxDate={dayjs()}
        />
        <FormControl className={classes.formControl} size="small">
            <InputLabel id="period-label">Select Period</InputLabel>
            <Select
              labelId="period-label"
              id="period"
              value={selectedPeriod}
              onChange={(e) => handleSelectPastPeriod(e.target.value)}
              size="small"
            >
              <MenuItem value="past7days">Past 7 Days</MenuItem>
              <MenuItem value="past1month">Past 1 Month</MenuItem>
              <MenuItem value="past3months">Past 3 Months</MenuItem>
            </Select>
          </FormControl>
        <FormControl className={classes.formControl} size="small">
          <InputLabel id="groupby-label">Group By</InputLabel>
          <Select
            labelId="groupby-label"
            id="groupby"
            value={groupby}
            onChange={(e) => setGroupBy(e.target.value)}
            size="small"
          >
            <MenuItem value="Service">Service</MenuItem>
            <MenuItem value="Account">Account</MenuItem>
            <MenuItem value="Application">Application</MenuItem>
            <MenuItem value="Project">Project</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} size="small">
          <InputLabel id="granularity-label">Granularity</InputLabel>
          <Select
            labelId="granularity-label"
            id="granularity"
            value={granularity}
            onChange={(e) => setGranularity(e.target.value)}
            size="small"
          >
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row'}}>
        <FormControl className={classes.formControl2} >
        <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...appLists]}
                    disableCloseOnSelect
                    value={appList}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(appList, appLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Applications" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, appList, setAppList, appLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
        </FormControl>
        <FormControl className={classes.formControl2} >
        <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...projectLists]}
                    disableCloseOnSelect
                    value={selectedProjects}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedProjects, appLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Projects" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, selectedProjects, setSelectedProjects, projectLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
        </FormControl>
        <FormControl className={classes.formControl2} >
            <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...accountLists]}
                    disableCloseOnSelect
                    value={selectedAccounts}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedAccounts, accountLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Accounts" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, selectedAccounts, setSelectedAccounts, accountLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
        </FormControl>
        <FormControl className={classes.formControl2} >
        <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...serviceLists]}
                    disableCloseOnSelect
                    value={selectedServices}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedServices, serviceLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Services" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, selectedServices, setSelectedServices, serviceLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
        </FormControl>
        </Box>
      </form>
      
      <Box
        gridColumn="span 12"
        gridRow="span 4"
        backgroundColor={colors.primary[400]}
      >
        <Box
          mt="25px"
          p="0 30px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
              Cost Explorer(Bar Diagram)
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color={colors.grey[100]}>
              Total Cost: {totalSum}
            </Typography>
            <Typography variant="body1" color={colors.grey[100]}>
              Average {granularity === 'monthly' ? 'Monthly' : 'Daily'} Cost: {averageCost}
            </Typography>
            <Typography variant="body1" color={colors.grey[100]}>
              {groupby} Count: {count}
            </Typography>
          </Box>
        </Box>

        {transformedData.length > 0 && (
          <Box height="350px" m="5px 0 0 0">
            <MyResponsiveBar data={transformedData} isDashboard={true} />
          </Box>
        )}
      </Box>

      <DataTable res={formattedData} title="Cost and usage"/>
    </Box>
    </LocalizationProvider>
  );
};

export default CostExplorer;
