import { createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
export const api = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    reducerPath: "adminApi",
    endpoints: (build) =>({
        nodePingMonitoring: build.query({ query: () =>"alertmonitoring/nodepingmonitoring/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU"}),
        resource: build.query({ query: () =>"resource/resource/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU"}),
        costAnomaly: build.query({ query: () =>"resource/costanomaly/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU"}),
        instance: build.query({ query: () =>"resource/instance/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU"})

    })
})

export const {
    useNodePingMonitoringQuery,
    useResourceQuery,
    useCostAnomalyQuery,
    useInstanceQuery
}= api;