import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, InputLabel, Checkbox, ListItemText, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import DataTable from 'pages/newtable';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TagRenderer from 'components/TagRenderer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 400,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AWSBilling = () => {
  const classes = useStyles();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedData = localStorage.getItem('filtersData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);
  const email = result.email;
  const appLists = result.billing_apps;
  const projectLists=result.projects;
  const accountLists = result.accounts;
  const awsVendorBillingUrl = `${baseUrl}resource/awsappbilling/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const [selectedMonth, setSelectedMonth] = useState(dayjs().subtract(1, 'month'));
  const [appList, setAppList] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const app_list = appList.length > 0 ? appList : appLists;
    try {
      const response = await fetch(`${awsVendorBillingUrl}?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          app_list: app_list.includes('Select All') ? appLists : app_list,
          projects: selectedProjects.includes('Select All') ? projectLists : selectedProjects,
          month: selectedMonth.format('M-YYYY'),
          account:  selectedAccounts.includes('Select All') ? accountLists : selectedAccounts,
        }),
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedMonth, appList, selectedAccounts, selectedProjects]);

  const handleSelectAll = (newValue, list, setList, allItems) => {
    if (Array.isArray(newValue)) {
      if (newValue.includes('Select All')) {
        if (list.length === allItems.length) {
          setList([]); 
        } else {
          setList(allItems);
        }
      } else {
        setList(newValue); 
      }
    }
  };

  const isAllSelected = (list, allItems) => list.length === allItems.length;
  const handleSelecteMonthChange = (date) => {
    setSelectedMonth(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box m="20px">
        <form>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <DatePicker
              id="month"
              label="Month"
              views={['year', 'month']}
              format="MM-YYYY"
              maxDate={dayjs().subtract(1, 'month')}
              value={selectedMonth}
              onChange={handleSelecteMonthChange}
              sx={{ width: 120, marginTop: "8px", }}
              slotProps={{ textField: { InputProps: { size: 'small' } } }}
            />

            <FormControl className={classes.formControl} >
                <Autocomplete
                        multiple
                        size="small"
                        options={['Select All', ...appLists]}
                        disableCloseOnSelect
                        value={appList}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={option === 'Select All' ? isAllSelected(appList, appLists) : selected}
                                />
                                {option}
                            </li>
                        )}
                        renderInput={(params) => <TextField {...params} label="Applications" />}
                        onChange={(event, newValue) => {
                            handleSelectAll(newValue, appList, setAppList, appLists);
                          }}
                        renderTags={(value, getTagProps) => (
                            <TagRenderer value={value} getTagProps={getTagProps} />
                          )}
                    />
            </FormControl>

            <FormControl className={classes.formControl} >
                <Autocomplete
                        multiple
                        size="small"
                        options={['Select All', ...projectLists]}
                        disableCloseOnSelect
                        value={selectedProjects}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={option === 'Select All' ? isAllSelected(selectedProjects, projectLists) : selected}
                                />
                                {option}
                            </li>
                        )}
                        renderInput={(params) => <TextField {...params} label="Projects" />}
                        onChange={(event, newValue) => {
                            handleSelectAll(newValue, selectedProjects, setSelectedProjects, projectLists);
                          }}
                        renderTags={(value, getTagProps) => (
                            <TagRenderer value={value} getTagProps={getTagProps} />
                          )}
                    />
            </FormControl>

            <FormControl className={classes.formControl} >
              <Autocomplete
                multiple
                id="accounts"
                size="small"
                options={['Select All', ...accountLists]}
                    disableCloseOnSelect
                    value={selectedAccounts}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedAccounts, accountLists) : selected}
                            />
                            {option}
                        </li>
                )}
                renderInput={(params) => <TextField {...params} label="Accounts" />}
                onChange={(event, newValue) => {
                        handleSelectAll(newValue, selectedAccounts, setSelectedAccounts, accountLists);
                      }}
                renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
              />
            </FormControl>
          </Box>
        </form>
      </Box>
      <DataTable res={data} title="AWS App Billing Data" />
    </LocalizationProvider>
  );
};

export default AWSBilling;
