import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DataTable from 'pages/newtable';
import TagRenderer from 'components/TagRenderer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
    maxWidth: 150,
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 400,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SaasInfo = () => {
  const classes = useStyles();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedData = localStorage.getItem('filtersData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);
  const email = result.email;
  const appLists = result.app_list;
  const accountLists = result.saas_accounts;
  const serviceLists = result.saas_services;
  const billingTypeLists = result.billing_types;
  const billingCycleLists = result.billing_cycles;
  const mfaLists = result.mfa_choices;
  const loginModes = result.login_modes;
  const contacts=result.contacts;

  const saasInfoUrl = `${baseUrl}resource/saasinfo/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;

  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedBillingTypes, setSelectedBillingTypes] = useState([]);
  const [selectedBillingCycles, setSelectedBillingCycles] = useState([]);
  const [selectedMfaFilter, setSelectedMfaFilter] = useState([]);
  const [selectedLoginModes, setSelectedLoginModes] = useState([]);
  const [data, setData] = useState([]);
  const labels = billingTypeLists.map(item => item.value);
  const fetchData = async () => {
    try {
      const response = await fetch(`${saasInfoUrl}?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          saas_accounts: selectedAccounts.includes('Select All') ? accountLists : selectedAccounts,
          applications: selectedApplications.includes('Select All') ? appLists : selectedApplications,
          services: selectedServices.includes('Select All') ? serviceLists : selectedServices,
          billing_types: selectedBillingTypes,
          billing_cycles: selectedBillingCycles,
          mfa: selectedMfaFilter,
          login_modes: selectedLoginModes,
        }),
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedAccounts, selectedApplications, selectedServices, selectedBillingTypes, selectedBillingCycles, selectedMfaFilter, selectedLoginModes]);
  const handleSelectAll = (newValue, list, setList, allItems) => {
    if (Array.isArray(newValue)) {
      if (newValue.includes('Select All')) {
        if (list.length === allItems.length) {
          setList([]); 
        } else {
          setList(allItems);
        }
      } else {
        setList(newValue); 
      }
    }
  };

  const isAllSelected = (list, allItems) => list.length === allItems.length;
  return (
    <Box m="20px">
      <form>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <FormControl className={classes.formControl2} >
            <Autocomplete
              multiple
              size="small"
              options={['Select All', ...accountLists]}
              disableCloseOnSelect
              value={selectedAccounts}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedAccounts, accountLists) : selected}
                            />
                            {option}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Saas Accounts" />}
              onChange={(event, newValue) => {
                handleSelectAll(newValue, selectedAccounts, setSelectedAccounts, accountLists);
              }}
              renderTags={(value, getTagProps) => (
                <TagRenderer value={value} getTagProps={getTagProps} />
              )}
            />
          </FormControl>

          <FormControl className={classes.formControl2} >
            <Autocomplete
              multiple
              size="small"
              options={['Select All', ...appLists]}
              disableCloseOnSelect
              value={selectedApplications}

              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={option === 'Select All' ? isAllSelected(selectedApplications, appLists) : selected}
                    
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Applications" />}
              onChange={(event, newValue) => {
                handleSelectAll(newValue, selectedApplications, setSelectedApplications, appLists);
              }}
              renderTags={(value, getTagProps) => (
                <TagRenderer value={value} getTagProps={getTagProps} />
              )}
            />
          </FormControl>

          <FormControl className={classes.formControl2} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...serviceLists]}
                    disableCloseOnSelect
                    value={selectedServices}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedServices, serviceLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Services" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, selectedServices, setSelectedServices, serviceLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>

          <FormControl className={classes.formControl2} >
            <Autocomplete
              multiple
              size="small"
              options={billingTypeLists}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              value={billingTypeLists.filter(option => selectedBillingTypes.includes(option.value))}
              onChange={(event, newValue) => {
                const selectedValues = newValue.map((option) => option.value);
                setSelectedBillingTypes(selectedValues);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Billing Types" />}
            />
          </FormControl>

          <FormControl className={classes.formControl2} >
            <Autocomplete
              multiple
              size="small"
              options={billingCycleLists}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              value={billingCycleLists.filter(option => selectedBillingCycles.includes(option.value))}
              onChange={(event, newValue) => {
                const selectedValues = newValue.map((option) => option.value);
                setSelectedBillingCycles(selectedValues);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Billing Cycles" />}
            />
          </FormControl>

          <FormControl className={classes.formControl2} >
            <Autocomplete
              multiple
              size="small"
              options={mfaLists}
              disableCloseOnSelect
              value={mfaLists.filter(option => selectedMfaFilter.includes(option.value))}
              onChange={(event, newValue) => {
                const selectedValues = newValue.map((option) => option.value);
                setSelectedMfaFilter(selectedValues);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="MFA" />}
            />
          </FormControl>

          <FormControl className={classes.formControl2} >
            <Autocomplete
              multiple
              size="small"
              options={loginModes}
              disableCloseOnSelect
              value={loginModes.filter(option => selectedLoginModes.includes(option.value))}
              onChange={(event, newValue) => {
                const selectedValues = newValue.map((option) => option.value);
                setSelectedLoginModes(selectedValues);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Login Modes" />}
            />
          </FormControl>
        </Box>
      </form>
      <DataTable res={data} title="Saas Tool Info Data" appLists={appLists} accounts={accountLists} services={serviceLists} labels={labels} contacts={contacts}/>
    </Box>
  );
};

export default SaasInfo;
