import React from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from 'react-router-dom';
import { ExpandMore,ChevronLeft } from '@mui/icons-material';
import updateNavItems from "./config-navigation";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import { validateSession } from "api/validation";

const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const [openSubmenu, setOpenSubmenu] = useState('');
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const handleMenuClick = async (lcText, subItems) => {
    

    if (subItems) {
      setOpenSubmenu(openSubmenu === lcText ? null : lcText);
    } 
    else {
      const isValid = await validateSession();

      if (isValid) {
        navigate(`/${lcText}`);
        setActive(lcText);
      } else {
        console.log('Session is invalid');
        navigate('/logout');
      }
    }
  };
  const handleSubmenuClick = async (lcText) => {
    const isValid = await validateSession();

    if (isValid) {
      navigate(`/${lcText}`);
      setActive(lcText);
    } else {
      console.log('Session is invalid');
      navigate('/logout');
    }
  };
  const encodedData=localStorage.getItem('encodedData');
  const decodedData = atob(encodedData);
  const data = JSON.parse(decodedData);
  const menuList = JSON.stringify(data.menu_list);
  const navItems = updateNavItems(JSON.parse(menuList));
  navItems.unshift({ text: 'Dashboard' });
  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                <Link to="/dashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
                     <Typography variant="h4" fontWeight="bold">
                       ICSM UI
                     </Typography>
                </Link>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
                    {navItems.map(({text,icon, subItems})=>{
                        const lcText = text.toLowerCase().replace(" ","");
                        const isSubMenuOpen = openSubmenu === lcText;
                        return (
                            <React.Fragment key={text}>
                            <ListItem  disablePadding>
                                <ListItemButton 
                                onClick={() => handleMenuClick(lcText, subItems)}
                                sx={{
                                    backgroundColor: active === lcText ? theme.palette.secondary[300] : "transparent",
                                    color: active === lcText ? theme.palette.primary[600] :  theme.palette.secondary[100],
                                }}
                                >
                                 
                                    <ListItemText primary={text}/>
                                  
                                    {subItems  && <ExpandMore sx={{ ml: "auto" }} />}
                                </ListItemButton>
                                </ListItem>
                                {subItems && isSubMenuOpen && (
                      <List sx={{ marginLeft: 2 }}>
                        {subItems.map((subItem) => {
                          const subText = subItem.text.toLowerCase().replace(/\s/g, "");
                          return (
                            <ListItem key={subItem.text} disablePadding>
                              <ListItemButton
                                onClick={() => handleSubmenuClick(subText, subItem.menu_url)}
                                sx={{ 
                                  paddingLeft: 2,
                                  backgroundColor: active === subText ? theme.palette.secondary[300] : "transparent",
                                  color: active === subText? theme.palette.primary[600] : theme.palette.secondary[100],
                                }}
                              >
                                <ListItemText primary={subItem.text} />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    )}
                         </React.Fragment>
                        )
                    })}
                </List>
          </Box>

          
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;