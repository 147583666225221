import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Modal,Box, useMediaQuery,Select,InputLabel, MenuItem, Button, Typography } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const DataTable = ({ res, title, contacts, roles }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedData = localStorage.getItem('encodedData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);
  const email = result.email;
  const userRole= result.user_role;
  const appInfoUpdateUrl=`${baseUrl}resource/update_appinfo_data/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const contactsUpdateUrl=`${baseUrl}resource/update_contacts_data/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const contactUrl=`${baseUrl}resource/get_contact_details/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const scheduleUrl=`${baseUrl}resource/update_schedule_data/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const data = res;
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const formatColumnName = (columnName) => {
    return columnName.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
  };
  const [editingValue, setEditingValue] = useState({});

  // Function to handle changes in multi-select editing
  const handleEditingValueChange = (column, value) => {
    setEditingValue(prevEditingValue => ({
      ...prevEditingValue,
      [column]: value
    }));
  };
  const initialState = {
    showGlobalFilter: (title === 'Schedule' || title === 'Shift Details') ? false : true,
    density: 'compact',
    showColumnFilters: (title === 'Schedule' || title === 'Shift Details') ? false : true,
    columnVisibility: { shift_timing: false },
    pagination: title === 'Contacts' ? { pageSize: 30 } : {pageSize: 25},

  };
   const [openModal, setOpenModal] = useState(false);
   const [clickedValue, setClickedValue] = useState('');
   const [contactDetails, setContactDetails] = useState({ email: '', name: '', phno: '' });

  const handleValueClick = async (value) => {
    try {
      const response = await fetch(`${contactUrl}?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          value: value
        })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch contact details');
      }
      const data = await response.json();
      setContactDetails({
        email: data.email_id,
        name: data.name,
        phno: data.phone_no,
        role:data.role,
      });
      setOpenModal(true);
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }
  };
  
   const handleCloseModal = () => {
     setOpenModal(false);
   };
  const columns = data?.length > 0 ? Object.keys(data[0]).map((column) => ({
    header: formatColumnName(column),
    accessorKey: column,
    Cell: ({ row }) => {
      const cellValue = row.original[column];
      if (cellValue && ['Devops_engineer_1','Devops_engineer_2','Devops_coordinators','PM','Developers'].includes(column)) {
        const values = cellValue?.split(',');
        return (
          <>
            {values.map((value, index) => (
              <React.Fragment key={index}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    handleValueClick(value);
                  }}
                  onMouseEnter={() => handleValueClick(value)}
                  // onMouseLeave={handleCloseModal}
                  style={{ color: 'lightblue', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {value}
                </a>
                {index < values.length - 1 && ', '}
              </React.Fragment>
            ))}
          </>
        );
      }
     
      else {
        return typeof cellValue === 'object' ? JSON.stringify(cellValue) : cellValue?.toString() || '';
      }
    },
    enableEditing:
      (
        (userRole === 'PM' && title === "Contacts") || (title === "Schedule")
      ),
      ...(column === 'role' && {
        editSelectOptions: roles,
        editVariant: 'select',
      }),
      ...(['mon','tue','wed','thu','fri','sat'].includes(column) &&{
        editSelectOptions: ['G','M','E','N','L'],
        editVariant: 'select',
      }),
    ...(((userRole === 'Developer' && column === 'Developers') ||
    (userRole !== 'Developer' && ['Devops_engineer_1','Devops_engineer_2','Devops_coordinators','PM','Developers'].includes(column))) && {
        Edit: ({row}) => {
            return (
              <>
              <InputLabel id="accounts-label">{formatColumnName(column)}</InputLabel>
              <Select
                labelId="labelId"
                id="selectId" 
                multiple
                value={editingValue[column] || row.original[column]?.split(',')||[row.original[column]]} 
                onChange={(event) => handleEditingValueChange(column, event.target.value)}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiMenuItem-root.Mui-selected': {
                        backgroundColor: 'blue', 
                      },
                    },
                  },
                }}
              >
                {contacts.map((email) => (
                  <MenuItem key={email} value={email}>
                    {email}
                  </MenuItem>
                ))}
              </Select>
              </>
            );
          },
     }),
  })) : [];
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };
  const formatValues = (values) => {
    return values?.filter(value => value !== null && value !== undefined && value !== '').join(',');
  };
const handleSaveAppInfo = ({ exitEditingMode, values }) => {
  const fetchData = async () => {
      try {
         
          const response = await fetch(`${appInfoUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  app_name: values.app_name,
                  devops_engineer_1: formatValues(editingValue['Devops_engineer_1']),
                  devops_engineer_2: formatValues(editingValue['Devops_engineer_2']),
                  devops_coordinators: formatValues(editingValue['Devops_coordinators']),
                  pm: formatValues(editingValue['PM']),
                  developers:formatValues(editingValue['Developers'])
              })
          });

          if (!response.ok) {
              setEditingValue({})
              throw new Error('Failed to update row');
          }
          setEditingValue({})
          const data = await response.json();
          alert(data.message); 
          window.location.reload();
      } catch (error) {

          setEditingValue({})
          console.error('Error:', error);
          alert('Failed to update data'); 
      }
  };

  fetchData();
  exitEditingMode();
};
const handleSaveContacts = ({ exitEditingMode, values }) => {
  const fetchData = async () => {
    try {
        const response = await fetch(`${contactsUpdateUrl}?email=${encodeURIComponent(email)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: values.email_id,
                name: values.name,
                phone_no: values.phone_no,
                shift_timing: values.shift_timing,
                role:values.role
            })
        });

        if (!response.ok) {
            throw new Error('Failed to update row');
        }

        const data = await response.json();
        alert(data.message);
        window.location.reload();
    } catch (error) {
        console.error('Error:', error);
        alert('Failed to update data');
    }
};

fetchData();
exitEditingMode();
};
const handleSave = ({ exitEditingMode, values }) => {
  const fetchData = async () => {
    try {
        const response = await fetch(`${scheduleUrl}?email=${encodeURIComponent(email)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: values.name,
                mon: values.mon,
                tue: values.tue,
                wed:values.wed,
                thu:values.thu,
                fri:values.fri,
                sat:values.sat,
            })
        });

        if (!response.ok) {
            throw new Error('Failed to update row');
        }

        const data = await response.json();
        alert(data.message);
        window.location.reload();
    } catch (error) {
        console.error('Error:', error);
        alert('Failed to update data');
    }
};

fetchData();
exitEditingMode();
};
  const onEditingRowSave = ({ exitEditingMode, values }) => {
   if (title === 'AppInfo Data') {
      handleSaveAppInfo({ exitEditingMode, values });
  }
  else if (title === 'Contacts') {
    handleSaveContacts({ exitEditingMode, values });
  }
  else if (title === 'Schedule') {
    handleSave({ exitEditingMode, values });
  }
};

  const table = useMaterialReactTable({
    columns,
    data,
    defaultColumn: { size: 40 },
    muiFilterTextFieldProps: {
      sx: { width: '100%', minWidth: 40 },
      placeholder: "",
    },
    onEditingRowSave,
    onEditingRowCancel: () => {
      setEditingValue({})
   },
    // editDisplayMode: "row",
    enableEditing: title === "AppInfo Data" || (title === "Contacts" && userRole === 'PM') || title === "Schedule",
    enableColumnActions: false,
    enableFullScreenToggle: false,
    initialState,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
        }}
      >
        <Typography variant="h4">{title}</Typography>
        <Button
          onClick={handleExportData}
          startIcon={<FileDownloadIcon style={{ color: 'green' }} />}
          style={{ color: 'green' }}
        >
          Export All Data
        </Button>
      </Box>
    ),
  });

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        m="10px"
        display="grid"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        <Box m="10px 0 0 0" sx={{ overflowX: 'auto' }}>
          <MaterialReactTable table={table}  />
        </Box>
      </Box>
    </LocalizationProvider>
    <React.Fragment>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
      >
        <DialogTitle>Contact Details</DialogTitle>
        <DialogContent>
        <TextField
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={contactDetails.email}
            disabled
          />
          <TextField
            margin="dense"
            id="name"
            name="name"
            label="Name"
            fullWidth
            variant="outlined"
            value={contactDetails.name}
            disabled
          />
          <TextField
            margin="dense"
            id="phno"
            name="phno"
            label="Phone Number"
            fullWidth
            variant="outlined"
            value={contactDetails.phno}
            disabled
          />
          <TextField
            margin="dense"
            id="role"
            name="role"
            label="Role"
            fullWidth
            variant="outlined"
            value={contactDetails.role}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  </>
  );
};

export default DataTable;