import React, { useEffect } from 'react';

const Login = () => {
  useEffect(() => {
    document.forms['ssoLoginForm'].submit();
  }, []);

  return (
    <form
      name="ssoLoginForm"
      action={process.env.REACT_APP_ACTION}
      method="post"
      id="login_form"
    >
      <input type="hidden" name="request_url" value={process.env.REACT_APP_REQUEST_URL} />
      <input type="hidden" name="callback_url" value={process.env.REACT_APP_LOGIN_CALLBACK_URL} />
      <input type="hidden" name="api_key" value={process.env.REACT_APP_API_KEY} />
      <input type="hidden" name="hash_value" value={process.env.REACT_APP_HASH_VALUE} />
      <input type="hidden" name="action" value="0" />
      <input type="hidden" name="legal_entity" value="IF" />
      <input type="hidden" name="force_consent" value="1" />
    </form>
  );
};

export default Login;
