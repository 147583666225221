import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, TextField} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from "../newtable";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TagRenderer from 'components/TagRenderer';
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
      maxWidth: 400,
    },
  }));
  
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
  
const MisAppServices = () => {
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const microserviceList=result.microservice;
    const appLists = result.app_list;
    const mstype = result.ms_type;
    const MASUrl = `${baseUrl}resource/misappservices/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
    const [appList, setAppList] = useState([]);
    const [MSList, setMSList] = useState([]);
    const [ms_type, setmstype] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${MASUrl}?email=${encodeURIComponent(email)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    app: appList.includes('Select All') ? appLists : appList,
                    ms_type: ms_type,
                    ms:MSList,

                })
            });
            const data = await response.json();
            setData(data);
        };

        fetchData();
    }, [appList,ms_type,MSList]);
    const handleSelectAll = (newValue, list, setList, allItems) => {
        if (Array.isArray(newValue)) {
          if (newValue.includes('Select All')) {
            if (list.length === allItems.length) {
              setList([]); 
            } else {
              setList(allItems);
            }
          } else {
            setList(newValue); 
          }
        }
      };
    
    const isAllSelected = (list, allItems) => list.length === allItems.length;
    return (
        <Box m="20px">
            <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...appLists]}
                    disableCloseOnSelect
                    value={appList}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(appList, appLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Applications" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, appList, setAppList, appLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <FormControl className={classes.formControl} size="small">
                <InputLabel id="app-list-label">MicroService</InputLabel>
                <Select
                    labelId="app-list-label"
                    id="app-list"
                    multiple
                    value={MSList}
                    onChange={(e) => setMSList(e.target.value)}
                    renderValue={(selected) => selected.join(', ')}
                    size="small"
                >
                    {microserviceList.map((app) => (
                        <MenuItem key={app} value={app}>
                            <Checkbox checked={MSList.indexOf(app) > -1} />
                            <ListItemText primary={app} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                id="cf-types"
                size="small"
                options={mstype}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="MicroService Type" />
                )}
                value={mstype.filter(option => ms_type.includes(option.value))}
                onChange={(event, newValue) => {
                  const selectedValues = newValue.map((option) => option.value);
                  setmstype(selectedValues);
                }}
              />
            </FormControl>
            <DataTable res={data} title="App & Services Data" appLists={appLists} mstype={mstype}/>
        </Box>
    );
};

export default MisAppServices;
