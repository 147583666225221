const updateNavItems = (menuList) => {
  return menuList.map(menu => {
    if (menu.subItems && menu.subItems.length > 0) {
      return {
        text: menu.text,
        subItems: menu.subItems.map(subItem => ({ text: subItem.text })),
      };
    } else {
      return {
        text: menu.text,
      };
    }
  });
};

export default updateNavItems;