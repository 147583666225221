
export const validateSession = async () => {
  try {
    const encodedData=localStorage.getItem('encodedData');
    const decodedData = atob(encodedData);
    const res = JSON.parse(decodedData);
    const session_id = res.sessId;
    const profile_id = res.profile_id;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    if (!session_id) {
      console.error('Session ID not found in local storage');
      return false;
    }

    const response = await fetch(`${baseUrl}validate_session/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({ session_id,profile_id }),
    });

    const data = await response.json();
    return data.is_valid;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
};
