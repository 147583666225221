import React, { useEffect } from 'react';

const Logout = () => {
  useEffect(() => {
    document.forms['ssoLogoutForm'].submit();
  }, []);

  return (
    <form
      name="ssoLogoutForm"
      action={process.env.REACT_APP_LOGOUT_ACTION}
      method="post"
      id="logout_form"
    >
      <input type="hidden" name="request_url" value={process.env.REACT_APP_LOGOUT_URL} />
      <input type="hidden" name="logout_callback_url" value={process.env.REACT_APP_REQUEST_URL} />
      <input type="hidden" name="api_key" value={process.env.REACT_APP_API_KEY} />
      <input type="hidden" name="hash_value" value={process.env.REACT_APP_LOGOUT_HASH_VALUE} />
      <input type="hidden" name="enable_redirect" id="enable_redirect" value="1"/>
    </form>
  );
};

export default Logout;