import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText,Button,TextField, Autocomplete  } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'pages/newtable';
import { useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import TagRenderer from 'components/TagRenderer';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        marginLeft:5,
        marginTop:8,
        minWidth: 120,
        maxWidth: 140,
    },
    formControl2: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 400,
      },
}));

const Squadcast = () => {
    const classes = useStyles();
    const location = useLocation();
    const { r } = location.state || {};
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const appLists =result.app_list;
    const appGroupsData = result.app_group;
    const status = ["resolved","acknowledged","triggered"];
    const service = result.service;
    const type = result.type;
    const alertSource = result.alert_source;
    const assignee = result.assignee;
    const [created, setCreated] = useState(null);
    const [typeList, setTypeList] = useState(["Uptime"]);
    const state = r?.state ? [r.state] : [];
    // const ttr_remarks = r?.ttr_remarks ? [r.ttr_remarks] : [];
    const squadcastUrl = `${baseUrl}alertmonitoring/squadcast/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
    const [appList, setAppList] = useState([]);
    const [appGroups, setAppGroups] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [alertSourceList, setAlertSourceList] = useState([]);
    const [assigneeList, setAssigneeList] = useState([]);
    const [data, setData] = useState([]);
    const [ttr, setTtr] = useState(false);
    const [noremarks, setNoRemarks] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState('today');
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    useEffect(() => {

        const fetchData = async () => {
            const response = await fetch(`${squadcastUrl}?email=${encodeURIComponent(email)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    status: statusList,
                    service: serviceList.includes('Select All') ? service : serviceList,
                    alertSource:alertSourceList,
                    assignee:assigneeList.includes('Select All') ? assignee : assigneeList,
                    startdate: startDate.format('YYYY-MM-DD'),
                    enddate: endDate.format('YYYY-MM-DD'),
                    ttr:ttr,
                    type:typeList,
                    applications: appList.includes('Select All') ? appLists : appList,
                    app_groups: appGroups.includes('Select All') ? appGroupsData : appGroups,
                    noremarks:noremarks

                })
            });
            const data = await response.json();
            setData(data);
        };

        fetchData();
    }, [typeList,serviceList,statusList,assigneeList,alertSourceList,startDate, endDate,ttr,selectedPeriod,appList, appGroups,noremarks]);

    const handleStartDateChange = (date) => {
        setStartDate(date);
      };
    const handleEndDateChange = (date) => {
        setEndDate(date);
      };
    const handleSelectPastPeriod = (period) => {
        const currentDate = dayjs();
        switch (period) {
          case 'today':
                setStartDate(currentDate);
                setEndDate(currentDate);
                break;
          case '1week':
            setStartDate(currentDate.subtract(6, 'day'));
            setEndDate(currentDate);
            break;
          case '1month':
            setStartDate(currentDate.subtract(1, 'month'));
            setEndDate(currentDate);
            break;
          case '3months':
            setStartDate(currentDate.subtract(3, 'month'));
            setEndDate(currentDate);
            break;
          default:
            break;
        }
        setSelectedPeriod(period);
      };
    const handleTtrGreaterThanTen = () => {
        setTtr((prevState) => !prevState);
      };
    const handleNoRemarks = () => {
        setNoRemarks((prevState) => !prevState);
      };
    const handleSelectAll = (newValue, list, setList, allItems) => {
        if (Array.isArray(newValue)) {
          if (newValue.includes('Select All')) {
            if (list.length === allItems.length) {
              setList([]); 
            } else {
              setList(allItems);
            }
          } else {
            setList(newValue); 
          }
        }
      };
    
    const isAllSelected = (list, allItems) => list.length === allItems.length;
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box m="20px">
            <FormControl className={classes.formControl} size="small">
                <InputLabel id="app-list-label">Type</InputLabel>
                <Select
                    labelId="app-list-label"
                    id="app-list"
                    multiple
                    value={typeList}
                    onChange={(e) => setTypeList(e.target.value)}
                    renderValue={(selected) => selected.join(', ')}
                    size="small"
                >
                    {type.map((app) => (
                        <MenuItem key={app} value={app}>
                            <Checkbox checked={typeList.indexOf(app) > -1} />
                            <ListItemText primary={app} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl} size="small">
            
                <Select
                labelId="period-label"
                id="period"
                value={selectedPeriod}
                onChange={(e) => handleSelectPastPeriod(e.target.value)}
                size="small"
                >
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="1week">Last 7 Days</MenuItem>
                <MenuItem value="1month">Last 1 Month</MenuItem>
                <MenuItem value="3months">Last 3 Months</MenuItem>
                </Select>
            </FormControl>
            <FormControl className={classes.formControl} size="small">
                <InputLabel id="app-list-label">Status</InputLabel>
                <Select
                    labelId="app-list-label"
                    id="app-list"
                    multiple
                    value={statusList}
                    onChange={(e) => setStatusList(e.target.value)}
                    renderValue={(selected) => selected.join(', ')}
                    size="small"
                >
                    {status.map((app) => (
                        <MenuItem key={app} value={app}>
                            <Checkbox checked={statusList.indexOf(app) > -1} />
                            <ListItemText primary={app} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl2} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...service]}
                    disableCloseOnSelect
                    value={serviceList}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(serviceList, service) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Services" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, serviceList, setServiceList, service);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <FormControl className={classes.formControl} size="small">
                <InputLabel id="app-list-label">Alert Source</InputLabel>
                <Select
                    labelId="app-list-label"
                    id="app-list"
                    multiple
                    value={alertSourceList}
                    onChange={(e) => setAlertSourceList(e.target.value)}
                    renderValue={(selected) => selected.join(', ')}
                    size="small"
                >
                    {alertSource.map((app) => (
                        <MenuItem key={app} value={app}>
                            <Checkbox checked={alertSourceList.indexOf(app) > -1} />
                            <ListItemText primary={app} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl2} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...assignee]}
                    disableCloseOnSelect
                    value={assigneeList}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(assigneeList, assignee) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Assignee" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, assigneeList, setAssigneeList, assignee);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <DatePicker
            id="start-date"
            label="Start Date"
            format="DD/MM/YYYY"
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            sx={{ width: 140,  marginLeft:"5px",marginTop:"8px" }}
            slotProps={{ textField: { InputProps: { size: 'small' } } }}
            maxDate={endDate}
            />

            <DatePicker
            id="end-date"
            label="End Date"
            format="DD/MM/YYYY"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            sx={{ width: 140,  marginLeft:"5px",marginTop:"8px" }}
            slotProps={{ textField: { InputProps: { size: 'small' } } }}
            maxDate={dayjs()}
            />
            <Button variant={ttr ? "contained" : "outlined"} className={classes.formControl}  onClick={handleTtrGreaterThanTen} sx={{ width: 80,height:37, color: "#FFFFFF" }}>TTR {'>'} 10 m</Button>
                      
            <FormControl className={classes.formControl2} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...appLists]}
                    disableCloseOnSelect
                    value={appList}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(appList, appLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Applications" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, appList, setAppList, appLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <FormControl className={classes.formControl2}>
              <Autocomplete
                multiple
                id="app-groups"
                size="small"
                options={[{ value: 'Select All', label: 'Select All' }, ...appGroupsData]}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={option.value === 'Select All' ? isAllSelected(appGroups, appGroupsData) : selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="App Groups" />
                )}
                value={appGroups.includes('Select All') ? [{ value: 'Select All', label: 'Select All' }]: appGroupsData.filter(option => appGroups.includes(option.value))}
                onChange={(event, newValue) => {
                  if (newValue.some(option => option.value === 'Select All')) {
                    if (appGroups.length === appGroupsData.length) {
                      setAppGroups([]); 
                    } else {
                      setAppGroups(appGroupsData.map(group => group.value));
                    }
                  } else {
                    const selectedValues = newValue.map(option => option.value);
                    setAppGroups(selectedValues);
                  }
                }}
                renderTags={(value, getTagProps) => (
                  <TagRenderer value={value} getTagProps={getTagProps} />
                )}
                
              />
            </FormControl>
            <Button variant={noremarks ? "contained" : "outlined"} className={classes.formControl}  onClick={handleNoRemarks} sx={{ width: 80,height:37, color: "#FFFFFF",textTransform: "none" }}>No Remarks</Button>
            <DataTable res={data} title="SquadCast Data"  />
        </Box>
        </LocalizationProvider>
    );
};

export default Squadcast;
