import React, { useMemo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MaterialReactTable, useMaterialReactTable, MRT_GlobalFilterTextField } from 'material-react-table';
import { Box, Button, useTheme, useMediaQuery, Typography, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme2";
import Header from "../../components/Header";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
const DataTable = ({ res, title }) => {
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const textColor = theme.palette.mode === 'dark' ? 'white' : 'black';
  const data = res;
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const formatColumnName = (columnName) => {
    return columnName.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
  };
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const years = [];
  for (let i = 2022; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }
  const months = Array.from({ length: 12 }, (_, index) => {
    const date = new Date(0);
    date.setUTCMonth(index);
    return date.toLocaleString('default', { month: 'long' });
  });

  
  const columnConfigurations = {
    account_row_key: {
      filterVariant: 'multi-select',
    },
    tag_status: {
      filterVariant: 'select',
    },
    audit_date: {
      accessorFn: (row) => {
        const day = new Date(row.audit_date);
        day.setHours(0, 0, 0, 0);
        return day;
      },
      filterFn: 'equals',
      filterVariant: 'date',
      sortingFn: 'datetime',
    },
    audit_period: {
      accessorFn: (row) => {
        const day = new Date(row.audit_period);
        day.setHours(0, 0, 0, 0);
        return day;
      },
            filterVariant: 'date-range',
      sortingFn: 'datetime',
      sortDescFirst: true,
    },
  };
  const initialState = {
    showColumnFilters: true,
    showGlobalFilter: true,
    sorting: data?.length > 0 && data?.some(item => 'audit_period' in item) ? [{
      id: 'audit_period',
      desc: true
    }] : [],// No sorting if audit_period is absent
  };
  
  const columns = useMemo(() => {
  if (!data || data.length === 0) {
    return [];
  }

  const newColumns = Object.keys(data[0]).map((column) => {
    const commonColumnProps = {
      header: formatColumnName(column),
      accessorKey: column,
      Cell: ({ row }) => {
        const cellValue = row.original[column];
        return typeof cellValue === 'object' ? JSON.stringify(cellValue) : cellValue?.toString() || '';
      },
    };

    const customColumnProps = columnConfigurations[column] || {};

    return {
      ...commonColumnProps,
      ...customColumnProps,
      muiFilterTextFieldProps: {
        sx: {
          minWidth: '250px',
        },
      },
    };
  });
  return newColumns;
}, [data]);

const table = useMaterialReactTable({
  columns,
  data,
  enableRowSelection: true,
  enableColumnFilterModes: true,
  enableFacetedValues: true,
  initialState,
  renderTopToolbarCustomActions: ({ table }) => {
  const datesExist = data.some(item => !!item.audit_date);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  return table && (
    <Box display="flex" flexDirection="row" alignItems="flex-start">
      {datesExist && (
        <>
          <Select
            value={selectedYear !== null ? selectedYear : ""}
            onChange={(e) => {
              const selectedValue = e.target.value === "" ? null : parseInt(e.target.value);
              setSelectedYear(selectedValue);
              if (selectedValue === "") {
                setSelectedMonth(null); // Reset month selection if all years are selected
                table.resetColumnFilters(); // Reset filters when all years are selected
              } else if (selectedValue === currentYear) {
                // Set selected month to current month if selected year is current year
                setSelectedMonth(currentMonth);
              }
            }}
            variant="outlined"
            sx={{ minWidth: 120, marginRight: 2 }}
            size="small"
          >
            <MenuItem value="">All Years</MenuItem>
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
          {selectedYear !== null && (
            <Select
              value={selectedMonth !== null ? selectedMonth : ""}
              onChange={(e) => setSelectedMonth(e.target.value === "" ? null : parseInt(e.target.value))}
              variant="outlined"
              sx={{ minWidth: 120, marginRight: 2 }}
              size="small"
            >
              <MenuItem value="">All Months</MenuItem>
              {selectedYear === currentYear ? (
                // Display months up to current month for current year
                months.slice(0, currentMonth + 1).map((month, index) => (
                  <MenuItem key={month} value={index}>
                    {month}
                  </MenuItem>
                ))
              ) : (
                // Display all months for previous years
                months.map((month, index) => (
                  <MenuItem key={month} value={index}>
                    {month}
                  </MenuItem>
                ))
              )}
            </Select>
          )}
        </>
      )}
      <Box>
        <Button
          onClick={() => {
            setSelectedYear(null);
            setSelectedMonth(null);
            table.resetColumnFilters();
            table.setGlobalFilter('');
          }}
          style={{ color: textColor }}
        >
          Reset Filters
        </Button>
      </Box>
    </Box>
  );
},
});

useEffect(() => {
  if (table && data && data.length > 0 && 'audit_period' in data[0]) {
    if (selectedYear === null) {
      // Reset filters for audit period column
      table.setColumnFilters(prevFilters => prevFilters.filter(filter => filter.id !== 'audit_period'));
    } else if (selectedMonth === null && selectedYear !== null) {
      // Filter based on the selected year
      const startDate = new Date(selectedYear, 0, 1); // Start of the selected year
      const endDate = new Date(selectedYear + 1, 0, 1); // Start of the next year
      endDate.setDate(endDate.getDate() - 1)
      const yearRangeFilter = { id: 'audit_period', value: [startDate, endDate] };

      // Remove existing filters for audit period column
      table.setColumnFilters(prevFilters => prevFilters.filter(filter => filter.id !== 'audit_period'));

      // Apply year range filter
      table.setColumnFilters(prevFilters => [...prevFilters, yearRangeFilter]);
    } else if (selectedMonth !== null && selectedYear !== null) {
      // Filter based on the selected month and year
      const startDate = new Date(selectedYear, selectedMonth, 1); // Start of the selected month
      const endDate = new Date(selectedYear, selectedMonth + 1, 0); // End of the selected month
      const monthRangeFilter = { id: 'audit_period', value: [startDate, endDate] };

      // Remove existing filters for audit period column
      table.setColumnFilters(prevFilters => prevFilters.filter(filter => filter.id !== 'audit_period'));

      // Apply month range filter
      table.setColumnFilters(prevFilters => [...prevFilters, monthRangeFilter]);
    }
  }
}, [selectedYear, selectedMonth, table, data]);

useEffect(() => {
  const instance = location.state?.instanceId; // Using optional chaining
  const applicationName = location.state?.applicationName;

  if (instance && table) {
    if(instance==="Missed"){
      table.setColumnFilters(prev => [...prev, { id: 'tag_status', value: instance }]);
    }else{
      table.setColumnFilters(prev => [...prev, { id: 'instance_id', value: instance }]);
    }
  }
if (applicationName && table) {
    table.setColumnFilters(prev => [...prev, { id: 'application_name', value: applicationName }]);
  }
}, [location.state, table]);

  return (
<LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        m="10px"
        display="grid"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        <Header title={title} />
        <Box m="10px 0 0 0" sx={{ overflowX: 'auto' }}>
          <MaterialReactTable  table={table} />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default DataTable;