import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AuthConstants = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const encodedData = params.get('data');

  useEffect(() => {

    if (encodedData) {
      localStorage.setItem('encodedData', encodedData);
      const decodedData = atob(encodedData);
      const result = JSON.parse(decodedData);
      const email = result.email;
      const baseUrl = process.env.REACT_APP_BASE_URL;
      fetch(`${baseUrl}get_filters?email=${encodeURIComponent(email)}`, {
          method: 'GET',
         
      })
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
        const encodedData = data.encoded_data;
        localStorage.setItem('filtersData', encodedData);
      })
      .catch(error => {
          console.error('There was a problem with your fetch operation:', error);
      });
      navigate('/dashboard');
    } else {
      console.error('Missing encoded data in the URL. Redirecting to login.');
      navigate('/login');
    }
  }, [encodedData]);

  return null;
};

export default AuthConstants;
